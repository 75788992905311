import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"
// import axios from "axios"

import { Formik, Field } from "formik"
import firebase from "gatsby-plugin-firebase"

const CompetitionPage = () => {
  const [submit, setSubmit] = React.useState({ done: false, message: "" })

  return (
    <Layout>
      <SEO title="Competition" />
      <Formik
        initialValues={{
          teamName: "",
          category: "",
          email: "",
          emailConfirmation: "",
          school: "",
          leaderName: "",
          phoneNumber: undefined,
          member1Name: "",
          member2Name: "",
          title: "",
          prototypeVideo: "",
          poster: undefined,
        }}
        validate={values => {
          const errors = {}
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Format email salah"
          }
          if (values.email !== values.emailConfirmation) {
            errors.emailConfirmation = "Email konfirmasi tidak sesuai"
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          const file = {}
          const fileDbRef = firebase
            .storage()
            .ref(
              `/competition/poster/${values.leaderName}-${values.phoneNumber}`
            )
          setSubmit({ done: true, message: "Sending" })
          fileDbRef
            .child(`poster/${values.poster.name}`)
            .put(values.poster)
            .then(snapshot =>
              snapshot.ref.getDownloadURL().then(downloadUrl => {
                file["poster"] = downloadUrl
              })
            )
            .then(() => {
              firebase
                .database()
                .ref(
                  `/competition-poster/${values.leaderName}-${values.phoneNumber}`
                )
                .set({
                  teamName: values.teamName,
                  category: values.category,
                  email: values.email,
                  school: values.school,
                  leaderName: values.leaderName,
                  phoneNumber: values.phoneNumber,
                  member1Name: values.member1Name,
                  member2Name: values.member2Name,
                  title: values.title,
                  prototypeVideo: values.prototypeVideo,
                  ...file,
                })
                .then(() => {
                  setSubmitting(false)
                  setSubmit({ done: true, message: "Success!" })
                  // axios.post(
                  //   "https://us-central1-sre-website-a43e8.cloudfunctions.net/sendEmailOnAstraRegistration",
                  //   { email: values.email }
                  // )
                })
                .catch(() => {
                  setSubmitting(false)
                  setSubmit({
                    done: true,
                    message: "Failed, Please refresh this page and try again",
                  })
                })
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              backgroundImage: `url(https://i.ibb.co/WWZVq2w/Submit.png)`,
            }}
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 min-h-screen">
              <div className="flex justify-center pt-8 pb-0 lg:pb-8">
                <div
                  className="flex flex-col mx-8 lg:mx-auto w-full lg:w-auto"
                  style={{ minWidth: `70%` }}
                >
                  <h1 className="mb-4 xl:mb-12 font-bold text-3xl xl:text-5xl text-green-500">
                    Kirimkan Karya Terbaikmu
                  </h1>
                  <label htmlFor="teamName" className="text-lg lg:text-xl">
                    Nama Tim:
                  </label>
                  <input
                    type="text"
                    name="teamName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.teamName}
                    className=" ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none "
                    placeholder="Tuliskan nama ketua disini"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.teamName && touched.teamName && errors.teamName}
                  </p>
                  <label htmlFor="leaderName" className="text-lg lg:text-xl">
                    Nama Ketua:
                  </label>
                  <input
                    type="text"
                    name="leaderName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.leaderName}
                    className=" ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none "
                    placeholder="Tuliskan nama ketua disini"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.leaderName &&
                      touched.leaderName &&
                      errors.leaderName}
                  </p>
                  <label htmlFor="member1Name" className="text-lg lg:text-xl">
                    Nama Anggota 1:
                  </label>
                  <input
                    type="text"
                    name="member1Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.member1Name}
                    className="ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none  mb-4"
                    placeholder="Tuliskan nama anggota 1 disini"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.member1Name &&
                      touched.member1Name &&
                      errors.member1Name}
                  </p>
                  <label htmlFor="member2Name" className="text-lg lg:text-xl">
                    Nama Anggota 2:
                  </label>
                  <input
                    type="text"
                    name="member2Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.member2Name}
                    className="ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none  mb-4"
                    placeholder="Tuliskan nama anggota 2 disini"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.member2Name &&
                      touched.member2Name &&
                      errors.member2Name}
                  </p>
                  <label htmlFor="category" className="text-lg lg:text-xl">
                    Kategori:
                  </label>
                  <div
                    role="group"
                    aria-labelledby="category"
                    className="my-2 py-1 mb-4"
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                      <label>
                        <Field
                          type="radio"
                          name="category"
                          value="Perguruan Tinggi"
                          className="mr-2"
                        />
                        Perguruan Tinggi
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="category"
                          value="SMA/SMK/Sederajat"
                          className="mr-2"
                        />
                        SMA/SMK/Sederajat
                      </label>
                    </div>
                  </div>
                  <label htmlFor="school" className="text-lg lg:text-xl">
                    Asal Universitas/Sekolah:
                  </label>
                  <input
                    type="text"
                    name="school"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.school}
                    className=" ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none  mb-4"
                    placeholder="Pelajar/Mahasiswa/Lainnya"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.school && touched.school && errors.school}
                  </p>
                  <label htmlFor="email" className="text-lg lg:text-xl">
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className=" ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none  mb-4"
                    placeholder="johndoe@example.com"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.email && touched.email && errors.email}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center pt-8 pb-0 lg:pb-8">
                <div
                  className="flex flex-col mx-8 lg:mx-auto w-full lg:w-auto"
                  style={{ minWidth: `70%` }}
                >
                  <label
                    htmlFor="emailConfirmation"
                    className="text-lg lg:text-xl"
                  >
                    Konfirmasi Email:
                  </label>
                  <input
                    type="email"
                    name="emailConfirmation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailConfirmation}
                    className=" ring-1 ring-black rounded-lg md:my-2 px-4 py-1 outline-none focus:outline-none  mb-4"
                    placeholder="johndoe@example.com"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.emailConfirmation &&
                      touched.emailConfirmation &&
                      errors.emailConfirmation}
                  </p>
                  <label htmlFor="phoneNumber" className="text-lg lg:text-xl">
                    No. HP:
                  </label>
                  <input
                    type="number"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    className=" ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none  mb-4"
                    placeholder="08123456789"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber}
                  </p>
                  <label htmlFor="title" className="text-lg lg:text-xl">
                    Judul Karya:
                  </label>
                  <textarea
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    className="ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none  mb-4"
                    placeholder="Tuliskan judul karya kamu disini"
                    required={true}
                    rows={6}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.title && touched.title && errors.title}
                  </p>
                  <label htmlFor="prototypeVideo" className="text-lg lg:text-xl">
                    Video Prototype:
                  </label>
                  <input
                    type="text"
                    name="prototypeVideo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.prototypeVideo}
                    className=" ring-1 ring-black rounded-lg  my-2 px-4 py-1 outline-none focus:outline-none mb-4"
                    placeholder="Tuliskan link ke video prototype"
                    required={true}
                  />
                  <p className="text-red-500 mb-2">
                    {errors.prototypeVideo && touched.prototypeVideo && errors.prototypeVideo}
                  </p>
                  <label htmlFor="poster" className="text-lg lg:text-xl">
                    Poster:
                  </label>
                  <input
                    id="poster"
                    name="poster"
                    type="file"
                    onChange={event => {
                      setFieldValue("poster", event.currentTarget.files[0])
                    }}
                    className="mt-2 mb-6 p-4 ring-1 ring-black rounded-xl"
                    required={true}
                  />
                  <div className="my-6 flex justify-end">
                    <Button
                      type="submit"
                      disabled={isSubmitting || submit.done}
                      className="bg-blue-700 hover:bg-blue-800 text-white rounded-xl"
                    >
                      Kirim
                    </Button>
                  </div>
                  <p className="text-lg">{submit.message || " "}</p>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Layout>
  )
}

export default CompetitionPage
